<template>
  <div class="h-full w-full">

    <div class="overflow-hidden w-full">
      <div class="page-content-2 pb-safe">
        <img src="@/assets/img/home/home_banner.png" class="w-full" alt="bg_top"/>
        <div class="bg-white rounded-t-3xl p-4 " style="margin-top:-60px">
          <img src="@/assets/img/home/home_icon.png" class="w-full" alt="bg_top"/>
        </div>
        <HomeMenu></HomeMenu>
        <div class="flex flex-row items-center bg-white px-4 py-2 ">
          <img src="@/assets/img/index/notice.png" style="height: 20px" alt="bg_top"/>
          <div class="ml-1 text-base">请在10月20日前完成以下任务！</div>
        </div>
        <div class="flex flex-col bg-white px-4">
          <div class="flex flex-row items-center bg-white px-4 py-2 mb-2 item">
            <img src="@/assets/img/index/ic_download.png" class="w-15">
            <div class="flex flex-col ml-2 flex-1 items-start">
              <div class="text-lg text-black font-bold">APP下载</div>
              <div class="text-base text-b333 ">领取<span class="text-primary">20000</span>元</div>
            </div>
            <button class="bg-primary text-white text-sm px-4 py-1 rounded" @click="jumpToPage('downloadApp')">{{isDownloadApp()}}
            </button>
          </div>
          <div class="flex flex-row items-center bg-white px-4 py-2 item">
            <img src="@/assets/img/index/ic_realname.png" class="w-15">
            <div class="flex flex-col ml-2 flex-1 items-start">
              <div class="text-lg text-black font-bold">实名享受</div>
              <div class="text-base text-b333 ">领取<span class="text-primary">20000</span>元</div>
            </div>
            <button class="bg-primary text-white text-sm px-4 py-1 rounded" @click="jumpToPage('realNameAuth')">
              {{ isAuth() }}
            </button>
          </div>
          <div class="flex flex-row items-center bg-white px-4 py-2 item">
            <img src="@/assets/img/index/ic_bind_card.png" class="w-15">
            <div class="flex flex-col ml-2 flex-1 items-start">
              <div class="text-lg text-black font-bold">绑定银行卡</div>
              <div class="text-base text-b333 ">领取<span class="text-primary">20000</span>元</div>
            </div>
            <button class="bg-primary text-white text-sm px-4 py-1 rounded" @click="jumpToPage('bindBankCard')">
              {{ isBindCard() }}
            </button>
          </div>
          <div class="flex flex-row items-center bg-white px-4 py-2 item">
            <img src="@/assets/img/index/ic_reward.png" class="w-15">
            <div class="flex flex-col ml-2 flex-1 items-start">
              <div class="text-lg text-black font-bold">邀请1位实名注册</div>
              <div class="text-base text-b333 ">每日领取<span class="text-primary">20000</span>元</div>
            </div>
            <button class="bg-primary text-white text-sm px-4 py-1 rounded" @click="jumpToPage('inviteUser')">
              {{ isInviteUser() }}
            </button>
          </div>
        </div>
        <img src="@/assets/img/index/footer.png" class="w-full" alt="footer"/>
      </div>
    </div>

    <popup class="bg-transparent" style="max-width: 80%;overflow-x: hidden;" v-model="dialog" position="center"
           :closeable="false">
      <div class="bg-white rounded-3xl">
        <div class="notice-title flex flex-row justify-center text-T010101 text-2xl items-center">
          <img src="@/assets/img/index/ic_notice.png" alt="" style="width: 25px;height: 25px;margin-right: 10px;">
          公告消息
        </div>
        <div class="bg-white p-2 m-0 w-full overflow-y-auto" style="max-height: 400px;">
          <p v-html="problem" class="text-[16px]"></p>

        </div>
        <div class=" py-4 text-[16px] flex justify-center" @click="closeDialog(1)">
          <div class="bg-primary text-white px-10 py-2 rounded-full">
            我知道了
          </div>
        </div>
      </div>
    </popup>
    <popup class="bg-transparent" style="max-width: 80%;overflow-x: hidden;" v-model="dialog2" position="center"
           :closeable="false">
      <div class="bg-white rounded-3xl">
        <div class="notice-title flex flex-row justify-center text-T010101 text-2xl items-center">
          <img src="@/assets/img/index/ic_notice.png" alt="" style="width: 25px;height: 25px;margin-right: 10px;">
          公告消息
        </div>
        <div class="bg-white p-2 m-0 w-full overflow-y-auto" style="max-height: 400px;">
          <p v-html="introduce" class="text-[16px]"></p>
        </div>
        <div class=" py-4 text-[16px] flex justify-center" @click="closeDialog(2)">
          <div class="bg-primary text-white px-10 py-2 rounded-full">
            我知道了
          </div>
        </div>
      </div>
    </popup>
  </div>
</template>
<script>
import Banner from "@/components/home/Banner";
import HomeMenu from "@/components/home/HomeMenu";
import News from "@/components/home/News";
import {mapActions, mapState} from "vuex";
import {isNullOrEmpty} from "@/utils";
import {NavBar, Popup,} from "vant";

export default {
  name: "index",
  components: {NavBar, Banner, HomeMenu, News, Popup},
  computed: {
    ...mapState("user", ["userInfo"]),
    ...mapState("systemInfo", ["systemInfo"]),
    title() {
      console.log('title', this.$route.meta.title)
      return this.$route.meta.title; // 获取路由的meta属性的key值
    }
  },
  data() {
    return {
      dialog: false,
      dialog2: false,
      problem: null,
      introduce: null,
    };
  },
  created() {
    // 从本地存储中获取 this.problem 的值
    const savedProblem = sessionStorage.getItem('problem');
    if (savedProblem) {
      this.problem = savedProblem;
    }
  },
  beforeDestroy() {
    // 在组件销毁之前将 this.problem 的值保存到本地存储
    sessionStorage.setItem('problem', this.problem);
  },
  watch: {
    systemInfo(newVal) {
      if (!isNullOrEmpty(newVal.problem)) {
        this.dialog = true;
        this.problem = newVal.problem;
      }
      if (!isNullOrEmpty(newVal.introduce)) {
        this.introduce = newVal.introduce;
      }
    }
  },
  mounted() {
    console.log("首页mounted");
    this.getUserInfo();
    this.getSystemInfo();
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    ...mapActions("systemInfo", ["getSystemInfo"]),
    jumpToPage(name) {
      if (name == 'downloadApp') {
        window.location.href = '/download.html';
        return
      }
      if (name == 'customService') {
        window.location.href = this.$config.KF_URL;
        return
      }

      this.$router.push({name});
    },
    closeDialog(type) {
      if (type === 1) {
        this.dialog = false;
        if (!isNullOrEmpty(this.introduce)) {
          setTimeout(() => {
            this.dialog2 = true;
          }, 1000);
        }
      } else {
        this.dialog2 = false;
      }
    },
    isAuth() {
      if (isNullOrEmpty(this.userInfo.auth)) {
        return '立即实名';
      }
      return '已完成';
    },
    isBindCard() {
      if (isNullOrEmpty(this.userInfo.bank)) {
        return '立即绑定';
      }
      return '已完成';
    },
    isInviteUser() {
      if (this.userInfo.realtjnum > 0) {
        return '立即邀请';
      }
      return '已完成';
    },
    isDownloadApp() {
      if (this.userInfo.is_reg_give ==0) {
        return '立即下载';
      }
      return '已完成';
    },

  },

};
</script>

<style scoped lang="less">
.van-nav-bar {
  background-color: #B41212;
}

.van-nav-bar /deep/ .van-nav-bar__title {
  color: #fff;
}

.bg-top {
  background-image: linear-gradient(180deg, #7496FF 0%, rgba(116, 150, 255, 0.00) 100%);
  height: 166px;
}

.bottom {
  background-image: linear-gradient(113deg, #4B49FF 0%, #7D9AFF 100%);
  border-radius: 4px;
}

.notice-title {
  width: 320px;
  height: 67px;
}

.item {
  box-shadow: 0px 0px 11.6px 0px rgba(193, 193, 193, 0.25);
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  color: #333;
  font-size: 16px;
}
</style>
