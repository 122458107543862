<template>
  <div class="page-container h-screen">
    <nav-bar
        :title="data.title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full"
        :border="false"
    />
    <div class="overflow-hidden w-full">
      <div class="overflow-y-auto h-full">
        <div class="rounded-sm bg-white p-2 m-2">
          <field
              v-model="balance"
              name="balance"
              label="可用余额:"
              readonly
              :center="true"
              input-align="right"
          />
          <field
              v-model="amount"
              type="number"
              name="提现数额"
              label="提现数额"
              placeholder="请输入提现数额"
              :center="true"
              input-align="right"
          />
          <div class="mt-8 mb-2 mx-2">
            <button v-if="canTodo" class="rounded bg-primary py-2 text-white w-full" @click="onSubmit">立即提现</button>
            <WithdrawCountDown v-else @onFinished="this.onFinished"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


import {mapActions, mapState} from "vuex";
import {Field, NavBar} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {WithdrawalType} from "@/config";
import {isNullOrEmpty} from "@/utils";
import WithdrawCountDown from "@/components/WithdrawCountDown";

export default {
  components: {NavBar, WithdrawCountDown, Field},
  computed: {
    ...mapState("user", ["userInfo"]),
  },
  data() {
    return {
      data: this.$route.query,
      WithdrawalType,
      amount: "",
      balance: this.$route.query.amount || 0,
      canTodo: false,
    }
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    onClickLeft() {
      this.$router.go(-1);
    },
    onFinished(status) {
      this.canTodo = status
    },
    onSubmit(event) {
      event.preventDefault();
      const {amount} = this;
      if (isNullOrEmpty(amount)) {
        this.$toast("请输入提现数额");
        return
      }
      console.log('申请提现', amount, this.balance)
      if (parseFloat(amount) > parseFloat(this.balance)) {
        this.$toast("提现金额不能大于可用余额");
        return;
      }
      if (this.data.type == 'salary' && this.balance < 30) {
        this.$dialog.alert({
          title: '提示',
          message: '最低30起提',
          theme: 'round-button',
        }).then(() => {

        });
        return
      }
      // if (this.data.type === WithdrawalType.CASH) {
      //   if(this.userInfo.bonusStock==0)
      //   {
      //     this.$dialog.confirm({
      //       title: '提示',
      //       message: '购买分红股即可提现,是否立即去购买?',
      //     })
      //         .then(() => {
      //           this.$router.push({path: '/dividend'});
      //         })
      //         .catch(() => {
      //         });
      //     return
      //   }
      // }
      axios.post(api.userWithdrawal, {
        amount,
        type: this.data.type,
      }).then((res) => {
        this.$toast("申请成功，正在审核");
        this.getUserInfo();
        this.onClickLeft();
      }).catch((err) => {
        err.msg && this.$toast(err.msg);
      });
    },
  },
}
</script>

<style scoped lang="less">
.van-cell {
  font-size: 16px;
}

.page-container /deep/ .van-field__label {
  font-weight: bold;
}
</style>
