import * as mtypes from './mutation-types.js'
import axios from "../net/axios";
import api from "@/net/api";

export default {
  namespaced:true,
  state: {
    userInfo: {},
  },
  mutations: {
    setUserInfo (state, payload) {
      state.userInfo = payload
    },
  },
  actions: {
    getUserInfo ({ commit }, payload) {
      console.log('获取用户信息')
      axios.get(api.userInfo)
          .then(res=>{
            commit('setUserInfo', res.data)
          })
          .catch(e=>{

          })
    },
  },
}

