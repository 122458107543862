<template>
  <div class="login_box relative">
    <img src="../assets/img/login/bg_login.png" alt="logo" class="w-full fixed left-0 top-0 -z-10"/>


    <div class="w-full max-w-md p-8 bg-white rounded-lg shadow-lg" id="app" style="margin-top: 160px">


      <div class="space-y-4">
        <div class="flex items-center border-b border-gray-300 py-2">
          <i class="iconfont icon-iconfontshouji text-gray-400 mr-2"/>
          <input class="w-full focus:outline-none text-gray-400"
                 v-model="mobile"
                 maxlength="11"
                 placeholder="请输入手机号" type="text">
        </div>
        <div class="flex items-center border-b border-gray-300 py-2">
          <Icon
              name="contact"
              class="icon text-gray-400 mr-2"
          ></Icon>
          <input class="w-full focus:outline-none text-gray-400"
                 v-model="invitaCode"
                 placeholder="请输入推荐码" type="text">
        </div>
        <div class="flex items-center border-b border-gray-300 py-2">
          <Icon
              name="lock"
              class="icon text-gray-400 mr-2"
          ></Icon>
          <input class="w-full focus:outline-none text-gray-400"
                 v-model="password"
                 placeholder="请输入密码" type="password">
        </div>
        <div class="flex items-center border-b border-gray-300 py-2">
          <Icon
              name="lock"
              class="icon text-gray-400 mr-2"
          ></Icon>
          <input class="w-full focus:outline-none text-gray-400"
                 v-model="password1"
                 placeholder="重新请输入密码" type="password">
        </div>
      </div>
      <button class="w-full bg-primary text-white py-2 rounded mt-10" @click="onRegister">立即注册</button>
      <button class="w-full bg-gray-200 text-black py-2 rounded-full mt-4" @click="jumpToPage('login')">已有账号？去登录</button>
    </div>
  </div>
</template>

<script>
import axios from "../net/axios";
import api from "../net/api";
import {isNullOrEmpty} from "../utils";
import {Icon} from "vant";
import UserStorage from "@/utils/user_storage";
import {CUSTOME_SERVICE_CHAT_URL, GROUP_CHAT_URL} from "@/config";
import QRCode from "qrcodejs2";

export default {
  components: {Icon},
  data() {
    return {
      mobile: "",
      password: "",
      password1: "",
      invitaCode: "",
    };
  },
  mounted() {
    console.log("邀请码", this.$route.query.code);
    this.invitaCode = this.$route.query.code || "";
    // this.generateGFQRCode();
    // this.generateKFQRCode();
  },
  methods: {
    jumpToPage(name) {
      if (name == 'customService') {
        window.location.href = this.$config.KF_URL;
        return
      }
      this.$router.push({name});
    },
    onRegister() {
      if (isNullOrEmpty(this.mobile)) {
        this.$toast("请输入手机号");
        return;
      }
      if (isNullOrEmpty(this.password)) {
        this.$toast("请输入密码");
        return;
      }
      if (isNullOrEmpty(this.password1)) {
        this.$toast("请再次输入密码");
        return;
      }
      if (this.password !== this.password1) {
        this.$toast("两次密码输入不一致");
        return;
      }
      if (isNullOrEmpty(this.invitaCode)) {
        this.$toast("请输入推荐码");
        return;
      }
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      let params = {
        mobile: this.mobile,
        password: this.password,
        password1: this.password1,
        invita_code: this.invitaCode,
      };
      axios
          .post(api.register, params)
          .then((res) => {
            this.$toast.clear();
            console.log("注册成功", res);
            UserStorage.setToken(res.data.token);
            window.token = res.data.token;
            this.$router.replace({name: "home"});
          })
          .catch((e) => {
            this.$toast.clear();
            e.msg && this.$toast(e.msg);
          });
    },
    generateGFQRCode() {
      // 清空容器元素中的内容
      const qrcodeContainer = document.getElementById("gf_qrcode");
      if (qrcodeContainer) {
        qrcodeContainer.innerHTML = '';
        this.url = GROUP_CHAT_URL;
        new QRCode(qrcodeContainer, {
          text: this.url,
          width: 100,
          height: 100
        });
      }
    },
    generateKFQRCode() {
      // 清空容器元素中的内容
      const qrcodeContainer = document.getElementById("kf_qrcode");
      if (qrcodeContainer) {
        qrcodeContainer.innerHTML = '';
        new QRCode(qrcodeContainer, {
          text: CUSTOME_SERVICE_CHAT_URL,
          width: 100,
          height: 100
        });
      }
    },
  },
};
</script>
<style scoped lang="less">
.login_box {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  padding: 80px 25px 25px 25px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  .name {
    font-size: 24px;
    font-weight: 550;
    color: #fff;
  }
}
</style>
