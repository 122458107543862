<template>
  <div class="h-full w-full flex flex-col">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
    />
    <div class="overflow-hidden w-full">
      <div class="page-content-2">
        <PullRefresh v-model="refreshing" @refresh="refreshData" class="h-full">
          <list
              v-model="loading"
              :finished="finished"
              :finished-text="list.length>10?'没有更多了':''"
              @load="loadMore"
              @refresh="refreshData"
          >
            <div v-for="(item, index) in list" :key="index" class="flex flex-col bg-white p-2 border-line"
                 :class="{ 'border-b': index !== list.length - 1 }">

              <div class="flex flex-row  ml-1 flex-1 justify-between">
                <div class="text-sm text-b333 font-bold">{{ item.source }}</div>
                <div class="text-sm " :class="getStatusColorClass(item.status)">{{
                    getReviewStatusLabel(item.status,item.ntype)
                  }}
                </div>
              </div>
              <div class="flex flex-row ml-1 justify-between my-1">
                <div class="text-sm text-b333">提现:{{ item.amount }}元</div>
                <div class="text-sm text-b333">{{ item.bankname }}({{ item.bankcode | getLastFourDigits }})</div>
              </div>
              <div class="flex flex-row ml-1 justify-between">
                <div class="text-sm text-b666">到账:{{ item.now_amount }}元</div>
                <div class="text-sm text-b333">{{ item.createtime | timeFormat('YYYY.MM.DD HH:mm') }}</div>
              </div>
<!--              <div v-if="item.status!=2"  class="text-sm text-red-500 ml-1 flex  flex-row items-center" >-->
<!--                已提交、周六日节假日、周一自动打款-->
<!--              </div>-->
<!--              <WithdrawRecordCountDown  v-if="getIsShowTips(item.ntype)"/>-->
            </div>
          </list>
          <Empty v-if="list.length === 0" description="暂无数据"/>
        </PullRefresh>
      </div>
    </div>
  </div>
</template>
<script>


import axios from "@/net/axios";
import api from "@/net/api";
import {Empty, List, NavBar, PullRefresh,} from "vant";
import {getNTypeLabel, getReviewStatusLabel, NType, ReviewStatus} from "@/config";
import WithdrawRecordCountDown from "@/components/WithdrawRecordCountDown";

export default {
  components: {WithdrawRecordCountDown, NavBar, List, Empty, PullRefresh,},
  data() {
    return {
      getReviewStatusLabel,
      getNTypeLabel,
      NType,
      title: this.$route.meta.title,
      count: 0,
      pageSize: 10,
      pageNo: 1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  filters: {
    getLastFourDigits(value) {
      return value.slice(-4);
    },
  },
  methods: {
    onClickLeft() {
      // 点击左侧返回按钮返回上一页
      this.$router.go(-1);
    },
    getIsShowTips(ntype) {
      if(ntype === 1||ntype === 2||ntype === 3||ntype === 6){
        return true;
      }
      else{
        return false;
      }
    },
    refreshData() {
      this.refreshing = true
      this.pageNo = 1
      this.count = 0
      this.loadMore()
    },
    loadMore() {

      axios
          .get(api.withdrawalRecordList, {
            params: {
              page: this.pageNo,
            }
          })
          .then((res) => {
            if (this.pageNo === 1) {
              this.list = res.data.list;
              this.count = res.data.count;
            } else {
              this.list = this.list.concat(res.data.list);
            }
            this.loading = false
            this.refreshing = false
            if (this.list.length < this.count) {
              this.pageNo++;
            } else {
              this.finished = true;
            }
          })
          .catch((err) => {
            this.loading = false
            this.refreshing = false
            this.finished = true;
            err.msg && this.$toast(err.msg);
          });
    },
    getStatusColorClass(status) {
      switch (status) {
        case ReviewStatus.PAID:
          return 'text-green-500'; // 返回绿色文字样式类
        case ReviewStatus.PAYMENT_FAILED:
          return 'text-red-500'; // 返回红色文字样式类
        default:
          return 'text-b333'; // 默认文字样式类
      }
    },
  },
};
</script>

<style scoped lang="less">

</style>
