<template>
  <div class="page-container h-screen page-body">
    <nav-bar
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full bg-transparent"
        :border="false"
    />
    <div class=" overflow-hidden w-full  h-full">
      <div class=" flex justify-center items-center w-full h-full">
        <div class="def-box rounded-xl m-4 px-2 py-4 flex flex-col justify-self-center w-full">
          <div class="text-xl text-red-500 self-center" style="margin-bottom: 50px" >百姓扶贫</div>
          <div id="qrcode" class="self-center my-2" ></div>
          <p class="text self-center">邀请码：{{ invitaCode }}</p>
          <div class="right">
            <button @click="onCopyText(invitaCode)" class="btn1">复制邀请码</button>
            <button @click="onCopyText(url)" class="btn1">复制推广链接</button>
          </div>
<!--          <div class="flex flex-col flex-start mt-2 ">-->
<!--            <div class="text-start">注册送50000元现金上市股权100股价值20000元 </div>-->

<!--            <div class="text-start">邀请10人实名注册送50000元现金上市股权200股价值50000元 </div>-->

<!--            <div class="text-start">邀请20人实名注册送100000元现金上市股权400股价值100000元 </div>-->

<!--            <div class="text-start">邀请50人实名注册送养200000元现金上市股权800股价值200000元 </div>-->

<!--            <div class="text-start">邀请100人实名注册送500000元现金上市股权1600股价值500000元 </div>-->

<!--            <div class="text-start">推荐奖一级20%二级2%三级1%</div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import QRCode from "qrcodejs2";
import {NavBar,} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";

export default {
  name: "InviteUser",
  components: {NavBar,},
  data() {
    return {
      invitaCode: "",
      shareUrl: "",
      shouldGenerateQRCode: false,
      url: '',
    };
  },
  computed: {
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    }
  },
  mounted() {
    this.loadData();
  },

  methods: {
    onClickLeft() {
      // 点击左侧返回按钮返回上一页
      this.$router.go(-1);
    },
    onCopyText(text) {
      const input = document.createElement("input");
      input.value = text;
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, input.value.length), document.execCommand('Copy');
      document.body.removeChild(input);
      this.$toast('复制成功！')
    },
    generateQRCode() {
      // 清空容器元素中的内容
      const qrcodeContainer = document.getElementById("qrcode");
      if (qrcodeContainer) {
        qrcodeContainer.innerHTML = '';
        this.url = `${this.shareUrl}/register?code=${this.invitaCode}`;
        new QRCode(qrcodeContainer, {
          text: this.url,
          width: 150,
          height: 150
        });
      }
    },
    loadData() {
      // 获取邀请码和推广链接
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      const request1 = axios.get(api.userInfo);
      const request2 = axios.get(api.systemInfo);
      axios.all([request1, request2])
          .then(axios.spread((response1, response2) => {
            this.invitaCode = response1.data.invita_code;
            this.shareUrl = response2.data.share_url;
            this.generateQRCode();
            this.$toast.clear();
          }))
          .catch(error => {
            // 处理错误
            console.error(error);
            this.$toast.clear();
          });
    }
  },


}
</script>

<style scoped lang="less">


.page-container {

}
.page-body{
  background-image: url('../../assets/img/invite/bg_invite.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.def-box {

  background-color:#FFF7EC;
  padding: 30px 15px;

  .left {
    width: 40%;
    text-align: center;
  }

  .right {
    display: flex;
    align-items: center;
    border-top: 1px dashed #AB0F13;;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .btn1 {
    display: block;
    background-image: linear-gradient(180deg, #FDE0B4 0%, #FFC771 100%);
    color: #AB0F13;
    border-radius: 4px;
    margin: 2rem auto .1rem;
    font-size: 14px;
    padding: 5px 10px;
  }

  .img {
    width: 60%;
    margin-top: 10px;
  }

  .text {
    color: #000;
    font-size: 16px;
  }
}

.share-address button {
  width: 45%;
  line-height: 35px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  background: rgba(255, 255, 255, .4);
  border-radius: 35px;
  display: block;
  margin: 0 auto;

  .btn1 {
    background-image: linear-gradient(180deg, #FDE0B4 0%, #FFC771 100%);
    width: 150px;
    margin-bottom: .1rem;
    color: #AB0F13;
  }
}




</style>
