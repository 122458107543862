<template>
  <div class="h-full w-full bg-white">

    <div class="overflow-hidden w-full bg-page">
      <div class="page-content-2 pb-safe relative">
        <img src="@/assets/img/profile/bg_mine.png" alt="" class="w-full">
        <div class="kf-container" @click="jumpToPage('customService')">
          <img src="@/assets/img/profile/kefu.png" alt="" style="height: 30px">
          <div class="text-white text-base font-bold text-center">客服</div>
        </div>
<!--        <Presidential/>-->
        <div class="flex flex-col m-4">
          <div class="flex flex-row items-center bg-info">
            <img class="rounded-full w-12 h-12 mr-2" @cilck="jumpToPage('userInfo')"
                 :src="userInfo.avatar?userInfo.avatar: require('@/assets/img/d-avatar.png')"
                 alt="">
            <div class=" flex-1 flex text-b333 flex-col">
              <div class="flex flex-row items-center justify-between">
                <p class="font-bold text-base">{{ displayName }}</p>
                <button class="copy-link-btn" @click="onCopyText">复制推广链接</button>
              </div>
              <div class="flex flex-row items-center justify-between">
                <div>
                  <p class="text-sm mt-1">手机号:{{ userInfo.mobile|maskPhoneNumber }}</p>
                  <p class="text-sm mt-1">邀请码:{{ userInfo.invita_code }}</p>
                </div>
                <img src="@/assets/img/profile/btn_sign.png" style="height: 26px;" @click="jumpToPage('signIn')"
                     alt=""/>
              </div>
            </div>
          </div>
          <Withdraw/>
          <Notice/>
          <img src="@/assets/img/profile/invite.png" alt="" class="w-full my-2"  @click="jumpToPage('inviteUser')">
        </div>

        <UserTools/>

      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import {WithdrawalType} from "@/config";
import axios from "@/net/axios";
import api from "@/net/api";
import {isNullOrEmpty} from "@/utils";
import Withdraw from "@/components/Withdraw";
import Notice from "@/components/Notice";
import UserTools from "@/components/UserTools";

export default {
  name: "index",
  components: {UserTools, Withdraw,Notice},
  computed: {
    ...mapState("user", ["userInfo"]),
    ...mapState("systemInfo", ["systemInfo"]),
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    },
    displayName: function () {
      if (this.userInfo.auth?.realname) {
        return this.userInfo.auth?.realname;
      } else if (this.userInfo.nickname) {
        return this.userInfo.nickname;
      } else {
        return "未设置";
      }
    },
  },
  data() {
    return {
      invitaCode: "",
      shareUrl: "",
      shouldGenerateQRCode: false,
      WithdrawalType,
      walletInfo: {},
      recommendRecords: [],
      list: [
        {
          id: 1,
          inviteNum: 1,
          amount: 100000,
          gradeIcon: require('@/assets/img/invite/empty.png'),
          title: '养老金证'
        },
        {
          id: 2,
          inviteNum: 3,
          amount: 300000,
          award: 3,
          gradeIcon: require('@/assets/img/invite/pz.png'),
          title: '团队奖励'
        },
        {
          id: 3,
          inviteNum: 10,
          amount: 1000000,
          award: 10,
          gradeIcon: require('@/assets/img/invite/lz.png'),
          title: '团队奖励'
        },
        {
          id: 4,
          inviteNum: 30,
          amount: 3000000,
          award: 30,
          gradeIcon: require('@/assets/img/invite/yz.png'),
          title: '团队奖励'
        },
        {
          id: 5,
          inviteNum: 50,
          amount: 5000000,
          award: 50,
          gradeIcon: require('@/assets/img/invite/tz.png'),
          title: '团队奖励'
        }
        ,
        {
          id: 6,
          inviteNum: 100,
          amount: 10000000,
          award: 100,
          gradeIcon: require('@/assets/img/invite/lvz.png'),
          title: '团队奖励'
        }
        ,
        {
          id: 5,
          inviteNum: 300,
          amount: 30000000,
          award: 300,
          gradeIcon: require('@/assets/img/invite/sz.png'),
          title: '团队奖励'
        }
        ,
        {
          id: 5,
          inviteNum: 500,
          amount: 50000000,
          salary: 20,
          gradeIcon: require('@/assets/img/invite/jz.png'),
          title: '分红卡'
        }
      ],
      refreshing: false,

    };
  },
  mounted() {
    this.getUserInfo();
    this.getSystemInfo();
    this.getUserWallet();
  },
  filters: {
    toIntegerOrZero(value) {
      if (value !== undefined) {
        return Math.floor(value);
      } else {
        return 0;
      }
    },
    maskPhoneNumber(phoneNumber) {
      const maskedNumber = phoneNumber?.replace(/(\d{3})\d{4}(\d{4})/, '\$1****\$2');
      return maskedNumber;
    },
    formatDecimal(value) {
      return parseFloat(value).toFixed(1);
    },
    formatDecimal2(value) {
      return parseFloat(value).toFixed(0);
    }
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    ...mapActions("systemInfo", ["getSystemInfo"]),

    jumpToPage(name) {
      if (name == 'downloadApp') {
        window.location.href = '/download.html';
        return
      }
      if (name == 'customService') {
        window.location.href = this.$config.KF_URL;
        return
      }
      this.$router.push({name});
    },
    getUserWallet() {
      axios.get(api.userWallet)
          .then((res) => {
            this.walletInfo = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    receiveNote() {
      if (isNullOrEmpty(this.userInfo.auth)) {
        this.$dialog.confirm({
          title: '提示',
          message: '领取本票需要实名认证，是否前往实名认证？',
        })
            .then(() => {
              this.$router.push({name: 'realNameAuth'});
            })
            .catch(() => {
            });
        return
      }
      axios
          .post(api.receiveNote)
          .then((res) => {
            console.log('res', res.data)
            this.$dialog.alert({
              title: '提示',
              message: `领取成功，请在“我的卡包”查看`,
            });
          })
          .catch((err) => {
            err.msg && this.$toast(err.msg);
          });
    },
    loadMore() {
      axios
          .get(api.receiveList)
          .then((res) => {
            if (res.data && res.data.length > 0) {
              this.recommendRecords = res.data.map(item => {
                return item.recom_num
              })
            }
            this.loading = false
            this.refreshing = false
            this.finished = true;
          })
          .catch((err) => {
            this.loading = false
            this.refreshing = false
            err.msg && this.$toast(err.msg);
          });
    },
    onCopyText() {
      const text = `${this.systemInfo.share_url}/register?code=${this.userInfo.invita_code}`;
      const input = document.createElement("input");
      input.value = text;
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, input.value.length), document.execCommand('Copy');
      document.body.removeChild(input);
      this.$toast('复制成功！')
    },
  },
};
</script>

<style scoped lang="less">
.van-nav-bar {
  background-color: #B41212;
}

.van-nav-bar /deep/ .van-nav-bar__title {
  color: #fff;
}

.page-content /deep/ .van-grid-item__text {
  font-size: 14px
}

.item-btn {
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 8px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #333435;
  margin: 20px auto auto;
}

.copy-link-btn {
  background: #FFE6C8;
  border-radius: 5px;
  font-family: MiSans;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
  color: #F14B47;
  padding: 5px 10px;
}

.bg-info {
  padding: 0 10px;
}
.kf-container{
  width: 61px;
  height: 78px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top:10px;
  right: 10px;
}

</style>
