<template>
  <div class="bg-white py-3">
    <div class="flex flex-row justify-evenly items-center mb-2">
      <img src="@/assets/img/index/1.png" style="height: 60px;" @click="receiveNote('百姓扶贫子弟证')">
      <img src="@/assets/img/index/2.png" style="height: 60px;" @click="receiveNote('房产证/物业卡')">
    </div>
    <p class="text-black text-center font-weight-bold text-xl mb-2">新闻动态</p>
    <img src="@/assets/img/index/ic_news.png" class="w-full">
    <div class="news-box">
      <div @click="jumpToPage(item.id)" class="item" v-for="(item, index) in newsList" :key="index">
        <div class="left">
          <p class="title">{{ item.title }}</p>
          <p class="lin-text">
            {{ item.description }}
          </p>
        </div>
        <img class="img-box" :src="item.thumb" alt=""/>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "@/net/axios";
import api from "@/net/api";
import {isNullOrEmpty} from "@/utils";

export default {
  data() {
    return {
      newsList: [],
      tjnum: 0,
      userInfo: {},
    };
  },
  mounted: function () {
    this.getNewsList();
  },
  methods: {
    jumpTo(name) {
      this.$router.push({name})
    },
    jumpToPage(id) {
      this.$router.push({
        name: 'newsDetails',
        params: {
          id
        }
      })
    },
    getNewsList() {
      const request1 = axios.get(api.newsList);
      const request2 = axios.get(api.userInfo);
      // 合并请求
      axios.all([request1, request2])
          .then(axios.spread((response1, response2) => {
            this.newsList = response1.data;
            //推荐总人数
            this.userInfo = response2.data;
            this.tjnum = response2.data.tjnum;
          }))
          .catch(err => {
            err.msg && this.$toast(err.msg);
          });
    },
    receiveNote(title) {
      if (isNullOrEmpty(this.userInfo.auth)) {
        this.$dialog.confirm({
          title: '提示',
          message: `领取${title}需要实名认证，是否前往实名认证？`,
        })
            .then(() => {
              this.$router.push({name: 'realNameAuth'});
            })
            .catch(() => {
            });
        return
      }
      if (this.tjnum < 150) {
        this.$dialog.confirm({
          title: '提示',
          message: `领取${title}需要至少邀请150人，是否前往邀请好友？`,
        })
            .then(() => {
              this.$router.push({name: 'inviteUser'});
            })
            .catch(() => {
            });
        return
      }
      axios
          .post(api.receiveNote)
          .then((res) => {
            console.log('res', res.data)
            this.$dialog.alert({
              title: '提示',
              message: `领取成功，请在“我的资产”查看`,
            });
          })
          .catch((err) => {
            err.msg && this.$toast(err.msg);
          });
    },
  },
};
</script>
<style scoped lang="less">
.news-icon {
  width: 70px;
  height: 21px;
}

.news-box {
  padding: 2%;
  width: 92%;
  border-radius: 5px;
  margin: 0px auto 20px;
}

.news-box .item {
  width: 100%;
  padding: 10px 0px;
  position: relative;
  display: flex;
}

.news-box .item:last-child {
  border-bottom: none;
}

.news-box .left {
  width: 75%;
  padding-right: 5%;
}

.news-box .title {
  font-size: 14px;
  font-weight: 550;
  margin-top: 0px;
  color: #333333;
  line-height: 22px;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

}

.news-box .lin-text {
  font-size: 12px;
  margin-top: 0px;
  color: #666666;
  line-height: 20px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-box .img-box {
  width: 30%;
}
</style>
