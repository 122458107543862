<template>
  <div class="page-container h-screen w-screen ">
    <nav-bar
        :title="details.title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full"
        :border="false"
    />
    <div class="flex-grow overflow-hidden w-full">
      <div class="overflow-y-auto h-full">
        <div class="contentBox">
          <div class="topBox">
            <p class="enTitle">NEWS</p>
            <p class="title">新闻动态</p>
          </div>
          <img style="margin: 10px 0;width: 100%;" :src="details.thumb" alt="">
          <div v-html="details.content" class="content">

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from "../../net/axios";
import api from "../../net/api";
import TitleBar from "@/components/TitleBar";
import {NavBar,} from "vant";
export default {
  name: "NewsDetails",
  components: {TitleBar,NavBar,},
  data() {
    return {
      details: {},
      id: this.$route.params.id,
    }
  },
  methods: {
    onClickLeft() {
      // 点击左侧返回按钮返回上一页
      this.$router.go(-1);
    },
    getData(id) {
      axios.get(api.newsDetails, {params: {id}})
          .then(res => {
            console.log('新闻详情', res)
            this.details = res.data
          })
          .catch(e => {
            console.log(e)
          })
    },
  },

  watch: {
    id: {
      immediate: true, //初始化时让handler调用一下
      handler(value) {
        this.getData(value)
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path != from.path) {
      this.id = to.params.id;
    }
    next()  // 一定要有next
  }

}
</script>

<style scoped lang="less">
img {
  max-width: 100%;
}

.enTitle {
  font-size: 30px;
  color: #eb3c00;
  letter-spacing: -3px;
  text-transform: uppercase;
}

.contentBox {
  width: 90%;
  padding: 10px;
  margin: 20px auto;
  background-color: #fff;
}

.contentBox .title {
  font-size: 30px;
  color: #bc8f4b;
}

.contentBox .content > h1 {
  color: #3f3e55;
  font-size: 30px;
}

.contentBox .content > h2 {
  color: #3f3e55;
  font-size: 18px;
  opacity: 0.9;
  padding-bottom: 25px;
}

.contentBox .content > p {
  font-size: 14px;
  line-height: 24px;
  color: #b1b1b1;
  padding-bottom: 20px;
}

.content {
  line-height: 1.8rem;
}
</style>
