<template>
  <div class="h-full w-full bg">

    <div class="overflow-hidden w-full">
      <div class="page-content-2">
        <PullRefresh v-model="refreshing" @refresh="refreshData" class="h-full">
          <list
              v-model="loading"
              :finished="finished"
              @load="loadMore"
              @refresh="refreshData"
              style="margin-top: 200px"
          >
            <div class="mx-4 flex flex-col">
              <div class="item-container"
                   v-for="(item,index) in list"
                   :key="index">
                <!--                <div class="item-header">-->
                <!--                  <div class="flex-1 flex flex-col justify-center items-center">一次购买</div>-->
                <!--                  <div class="flex-1 flex flex-col justify-center items-center">永久收益</div>-->
                <!--                  <div class="flex-1 flex flex-col justify-center items-center">{{item.price|formatDecimal2}}元</div>-->
                <!--                </div>-->
                <!--                <div class="text-base flex flex-row items-center mt-4">-->
                <!--                  <div class="flex-1 flex flex-col items-center justify-center">-->
                <!--                    <div class="text-lg font-bold">-->
                <!--                      <span style="color: #FF4902">{{ item.bonus|formatDecimal2 }}</span>元-->
                <!--                    </div>-->
                <!--                    <span>每日分红</span>-->
                <!--                  </div>-->
                <!--                  <div class="flex-1 flex flex-col items-center justify-center">-->
                <!--                    <div class="text-lg font-bold">-->
                <!--                      <span style="color: #FF4902">{{ item.stock|formatDecimal2 }}</span>股-->
                <!--                    </div>-->
                <!--                    <span>国债</span>-->
                <!--                  </div>-->
                <!--                  <div class="flex-1 flex flex-col items-center justify-center">-->
                <!--                    <button class="btn-buy" @click="jumpToPay(item)">立即购买</button>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                <div class="content-box rounded-full">-->
                <!--                  今日进度-->
                <!--                  <div class="flex flex-1 rounded-full progress-box">-->
                <!--                    <div class="complete-progress rounded-full" :style="{width:item.progress+'%'}">-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                  已售{{ item.progress }}%-->
                <!--                </div>-->
                <div class="rounded-t-md bg-E7CA98 text-8A5F4D p-2 flex flex-row items-center justify-between">
                  <div class="text-transparent">{{item.price}}元</div>
                  <div >国债期货</div>
                  <div>{{item.price}}元</div>
                </div>
                <div class="flex-row flex text-base">
                  <div class="relative text-transparent">
                    限量发售sddd
                    <div class="absolute left-0 top-0 bg-red-500 rounded-br-md text-white    px-2 text-sm">限量发售</div>
                  </div>
                  <div class="flex flex-col items-center pl-2 pr-6 my-4">
                    <div class="text-base text-T010101">国债期货</div>
                    <div class="text-base text-red-500 font-bold">{{item.stock|formatDecimal3}}万</div>
                  </div>
                  <div class="justify-center text-center flex flex-col items-center flex-1">立即到账</div>
                  <div class="  flex flex-row justify-center items-center px-2">
                    <div class="rounded-full bg-E7CA98 text-724A07 font-bold px-4 text-base"
                         @click="jumpToPay(item)">购买
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </list>
          <Empty v-if="isNullOrEmpty(list)&&loading===false&&refreshing==false" description="暂无数据"/>

        </PullRefresh>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "@/net/axios";
import api from "@/net/api";
import {Empty, List, PullRefresh} from "vant";
import {isNullOrEmpty} from "@/utils";

export default {
  name: "index",
  components: {List, Empty, PullRefresh,},
  data() {
    return {
      active: 0,
      isNullOrEmpty,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      walletInfo: {},
    };
  },
  mounted() {

  },
  filters: {
    formatDecimal(value) {
      return parseFloat(value).toFixed(1);
    },
    formatDecimal2(value) {
      return parseFloat(value).toFixed(0);
    },
    formatDecimal3(value) {
      if(!value)
        return "0"
      return (parseFloat(value)/10000).toFixed(0);
    },
  },
  computed: {
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的title值
    }
  },
  methods: {
    refreshData() {
      this.refreshing = true
      this.loadMore()
    },

    loadMore() {
      axios.get(api.welfareList)
          .then(res => {
            this.list = res.data.filter(item => item.ntype == 2);
            this.loading = false
            this.refreshing = false
            this.finished = true;
          })
          .catch(err => {
            this.loading = false
            this.finished = true
            this.refreshing = false
            err.msg && this.$toast(err.msg);
          });
    },
    jumpToPay(data) {
      this.$router.push({
        name: "buyDividend",
        query: {
          id: data.id,
          stock: data.stock,
          price: data.price,
          title: '国债期货',
          unit:'股'
        },
      });
    },

  },
};
</script>

<style scoped lang="less">
.van-nav-bar {
  background-color: #B41212;
}

.van-nav-bar /deep/ .van-nav-bar__title {
  color: #fff;
}

.bg {
  background-image: url('../../assets/img/dividend/banner-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.title-box {
  background-image: url('../../assets/img/bg-welfare.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 290px;
  height: 44px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -20px;
  color: #AB0F13;
  font-size: 16px;
}

.welfare-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #333;
  font-size: 14px;
  margin-bottom: 30px;
  flex-direction: column;
}

.list-item-content {
  top: 30px;
  background: #FFE7C1;
  border-radius: 20px;
  padding: 40px 20px 20px;
  display: flex;
  flex-direction: column;

}

.list-item-content .btn {
  background-image: linear-gradient(180deg, #FDE0B4 0%, #FFC771 100%);
  border-radius: 16px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #AB0F13;
  align-self: center;
  margin-top: 20px;
}

.content-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.5;
  background: #FFFFFF;
  color: #3E3E3E;
  padding: 5px 15px;
  font-size: 12px;
  margin-top: 20px;
}

.progress-box {
  width: 60%;
  background-color: #AB0F13;
  height: 10px;
  margin: 0 10px;
  opacity: 1;
}

.page-content /deep/ .van-loading__text {
  color: #fff;
}

.page-content /deep/ .van-pull-refresh__head {
  color: #fff;
}

.page-content /deep/ .van-empty__description {
  color: #fff;
}

.top-container {
  background-image: url('../../assets/img/dividend/bg.png');
  background-size: 100%;
  background-repeat: no-repeat;
  height: 260px;
  padding-top: 190px;
  padding-left: 70px;
  padding-right: 20px;
}

.limit-content {
  background: linear-gradient(90deg, rgba(255, 238, 89, 0) 0%, #FFEE59 20%, #FFEE59 80%, rgba(255, 238, 89, 0) 100%);
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}


.item-container {
  background: #FFFFFF;
  opacity: 0.9;
  box-shadow: 0px 0px 11.6px rgba(193, 193, 193, 0.25);
  border-radius: 10px;
  margin-bottom: 10px;

  .item-header {
    background: #F14B47;
    opacity: 0.9;
    box-shadow: 0px 0px 11.6px rgba(193, 193, 193, 0.25);
    border-radius: 10px 10px 0px 0px;
    color: #FFFFFF;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    padding: 10px 0;
  }


}

.btn-buy {
  background: #F25D59;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 14px;
  padding: 5px 10px;
  cursor: pointer;
}

.content-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.5;
  background: #FFFFFF;
  color: #3E3E3E;
  padding: 5px 15px;
  font-size: 12px;
  margin-top: 10px;
}

.progress-box {
  background-color: #E5E5E5;
  height: 6px;
  margin: 0 10px;

  .complete-progress {
    background-color: #F14B47;
    height: 6px;
  }
}

</style>
