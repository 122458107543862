<template>
  <div class="foot_menu w-full">
    <Tabbar v-model="active" active-color="#FE4B4C" inactive-color="#999999" :border="false" @change="changeTab">
      <TabbarItem
          v-for="(item, index) in menuList"
          :key="index"
      >
        <span>{{ item.title }}</span>
        <template #icon="props">
          <img :src="props.active ? item.sel_pic : item.pic"/>
        </template>
      </TabbarItem>
    </Tabbar>
  </div>
</template>

<script>
import axios from "../net/axios";
import api from "../net/api";
import UserStorage from "../utils/user_storage";
import {Icon, Tabbar, TabbarItem} from "vant";

export default {
  props: ['pageKey'],
  components: {
    Icon,
    Tabbar,
    TabbarItem,
  },
  data() {
    return {
      active: "首页",
      menuList: [
        {
          "title": "首页",
          "isShow": 1,
          "url": "/home",
          "pic": require('@/assets/img/nav/menu_home.png'),
          "sel_pic": require('@/assets/img/nav/menu_home_checked.png'),
        },
        {
          "title": "百姓补贴",
          "isShow": 1,
          "url": "/invite",
          "pic": require('@/assets/img/nav/menu_invite.png'),
          "sel_pic": require('@/assets/img/nav/menu_invite_checked.png'),
        },
        // {
        //   "title": "百姓国债",
        //   "isShow": 1,
        //   "url": "/assets",
        //   "pic": require('@/assets/img/nav/menu_fund.png'),
        //   "sel_pic": require('@/assets/img/nav/menu_fund_checked.png'),
        // },
        {
          "title": "银行卡",
          "isShow": 1,
          "url": "/bankCardList",
          "pic": require('@/assets/img/nav/menu_card.png'),
          "sel_pic": require('@/assets/img/nav/menu_card_checked.png'),
        },
        {
          "title": "百姓保险",
          "isShow": 1,
          "url": "/insurance",
          "pic": require('@/assets/img/nav/menu_bxbx.png'),
          "sel_pic": require('@/assets/img/nav/menu_bxbx_checked.png'),
        },
        {
          "title": "扶贫金",
          "isShow": 1,
          "url": "/helpMoney",
          "pic": require('@/assets/img/nav/menu_fp.png'),
          "sel_pic": require('@/assets/img/nav/menu_fp_checked.png'),
        },
        // {
        //   "title": "在线客服",
        //   "isShow": 1,
        //   "url": this.$config.KF_URL,
        //   "pic": require('@/assets/img/nav/menu_kf.png'),
        //   "sel_pic": require('@/assets/img/nav/menu_kf_checked.png'),
        // },
        {
          "title": "我的",
          "isShow": 1,
          "url": "/profile",
          "pic": require('@/assets/img/nav/menu_my.png'),
          "sel_pic": require('@/assets/img/nav/menu_my_checked.png'),
        }],
    };
  },
  created() {
    this.$bus.$on('active', this.handleMessage);
  },
  beforeDestroy() {
    this.$bus.$off('active', this.handleMessage); // 在组件销毁前移除监听器
  },
  mounted() {
    console.log('加载页面')
    this.init();
  },
  methods: {
    handleMessage(index) {
      console.log('收到事件', index)
      this.active = index;
    },
    init() {
      const title = this.$route.meta.title;
      console.log('title', title)
      switch (title) {
        case '首页':
          this.active = 0
          break;
        case '百姓补贴':
          this.active = 1
          break;
        case '银行卡':
          this.active = 2
          break;
        case '百姓保险':
          this.active = 3
          break;
        case "扶贫金":
          this.active = 4
          break;
        case '我的':
          this.active = 5
          break;

      }
    },
    changeTab(index) {
      this.active = index;
      const item = this.menuList[index];
      if (item.title == '在线客服') {
        window.location.href = item.url;
        return;
      }
      this.navigateTo(item.url);
    },
    navigateTo(url) {
      this.$router.push(url);
    },
    //获取导航菜单
    getNavigation() {
      axios
          .get(api.navList)
          .then((res) => {
            const data = res.data;
            let list = [];
            data.map((item) => {
              if (item.isShow) {
                list.push(item);
              }
            });
            console.log("菜单列表", list);
            this.menuList = list;
            UserStorage.setNavList(list);
          })
          .catch((err) => {
            err.msg && this.$toast(err.msg);
          });
    },
  },
};
</script>

<style lang="less" scoped>
.foot_menu {
  height: 50px;
  background-color: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.van-tabbar-item__icon img {
  height: 1.5rem;
}
</style>
