export const MODAL = {
  CHANGE_SIDEBAR_STATE: 'CHANGE_SIDEBAR_STATE',
  CHANGE_LOADING_STATE: 'CHANGE_LOADING_STATE',
  CHANGE_USERINFO_STATE: 'CHANGE_USERINFO_STATE',
  ADD_VISITE_STATE: 'ADD_VISITE_STATE',
  DEL_VISITE_STATE: 'DEL_VISITE_STATE',
  CHANGE_CUSTOMER_STATE: 'CHANGE_CUSTOMER_STATE',
  CHANGE_TABS_STATE: 'CHANGE_TABS_STATE',
  CHANGE_PAGE_STATE: 'CHANGE_PAGE_STATE',
  CHANGE_IMAGE_CAROUSEL_STATE: 'CHANGE_IMAGE_CAROUSEL_STATE',
}
