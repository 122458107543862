import { render, staticRenderFns } from "./modifyPwd.vue?vue&type=template&id=6f83826a&scoped=true"
import script from "./modifyPwd.vue?vue&type=script&lang=js"
export * from "./modifyPwd.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f83826a",
  null
  
)

export default component.exports